import { useContext } from "react";
import { Box, Typography } from "@mui/material/";
import { MicroCopyContext } from "@edenred/micro-copy";
import { StaticImage } from "gatsby-plugin-image";
import Page from "../components/Page";

export default function Maintenance() {
  const getMicroCopy = useContext(MicroCopyContext);

  return (
    <Page title="Maintenance" sx={{ marginTop: "8px" }}>
      <Box
        sx={{
          margin: "auto",
          padding: "20px",
          textAlign: "center"
        }}
      >
        <StaticImage
          src="https://cdn.edenred.uk.com/services/src/logo.png"
          alt="Edenred Logo"
          style={{
            maxWidth: "250px",
            width: "100%",
            margin: "auto",
            verticalAlign: "middle"
          }}
        />
      </Box>
      <Box
        sx={{
          maxWidth: "70%",
          margin: "auto",
          paddingTop: "25px",
          textAlign: "center"
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{ margin: 3, fontWeight: 600 }}
        >
          {getMicroCopy("error.maintenance.title")}
        </Typography>
        <Typography
          variant="h6"
          component="h3"
          sx={{ margin: 3, fontWeight: 500 }}
        >
          {getMicroCopy("error.maintenance.message")}
        </Typography>
        <Typography variant="body1" component="h5" sx={{ margin: 3 }}>
          {getMicroCopy("error.maintenance.footer")}
        </Typography>
      </Box>
    </Page>
  );
}
